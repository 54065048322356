@import "../const"

.#{$ns}map
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  z-index: 1
  min-height: calc(var(--windowDividerH) * 300)

  .mapboxgl-ctrl-attrib
    display: none !important
