@import "../const"

.#{$ns}map-control
  $block: #{&}

  &#{$block}
    position: relative
    padding: calc(var(--windowDividerW) * 16)
    background: none
    outline: none
    border: none
    cursor: pointer
    background: transparent
    transition: background 0.2s
    -webkit-tap-highlight-color: transparent

    &:active
      background: rgba(0, 0, 0, 0.14)

    &_disabled
      pointer-events: none

  #{$block}__icon
    width: calc(var(--windowDividerW) * 17)
    height: calc(var(--windowDividerW) * 17)
    display: flex
    align-items: center
    justify-content: center
