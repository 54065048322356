@import "./fonts/all"

html,
body
  padding: 0
  margin: 0
  font-family: 'PT Root UI', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
  -webkit-font-smoothing: antialiased


input, select, textarea, button
  font-family: inherit

*
  box-sizing: border-box
  -webkit-user-select: none /* Safari */
  -ms-user-select: none /* IE 10 and IE 11 */
  user-select: none /* Standard syntax */


.ReactModal__Body--open
  overflow: hidden


:root
    --windowDividerW: 1dvw / 19.2
    --windowDividerH: 1dvh / 10.8


@media only screen and (max-width: 1920px)
    :root 
        --windowDividerW: 1px
        --windowDividerH: 1px