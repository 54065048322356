$ns: 'bm-'
$font-base: 'RussianRail G Pro', sans-serif
$font-base-extend: 'RussianRail G Pro Extend', sans-serif
$font-ibm-plex: 'IBM Plex Sans', sans-serif

$menu-xl: calc(120 * var(--windowDividerW))
$menu-md: calc(96 * var(--windowDividerW))
$menu-sm: 0px

$header-xl: 74px
$header-md: 60px
$header-sm: 0px
