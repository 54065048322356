@import "../../const"

.#{$ns}button
  $block: #{&}
  $blockWithMod: #{$block}_default

  &#{$blockWithMod}
    border: none
    outline: none
    background: none
    cursor: pointer
    -webkit-tap-highlight-color: transparent

    &:disabled
      cursor: default
