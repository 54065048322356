@import "../../../const"

.#{$ns}card
  $block: #{&}
  $blockWithMod: #{$block}_modal

  &#{$blockWithMod}

    #{$block}__title
      font-family: $font-base
      font-style: normal
      font-weight: 400
      font-size: calc(var(--windowDividerW) * 32)
      line-height: calc(var(--windowDividerW) * 36)
      display: inline-block
      color: var(--bm-card-modal-title)

    @media only screen and (max-width: 767px)
      #{$block}__title
        font-size: 24px
        line-height: 28px
        text-align: left

    @media only screen and (min-width: 768px) and (max-width: 1023px)
      #{$block}__title
        font-size: 24px
        line-height: 28px
        text-align: left

    @media only screen and (min-width: 1024px) and (max-width: 1439px)
      #{$block}__title
        font-size: 24px
        line-height: 28px

    @media only screen and (min-width: 1440px) and (max-width: 1919px)
      #{$block}__title
        font-size: 28px
        line-height: 32px
