@import "../../const"

.#{$ns}card
  $block: #{&}

  &#{$block}_modal
    position: relative
    width: 50vw

  &#{$block}_to-right
    border-radius: calc(var(--windowDividerW) * 20) 0 0 calc(var(--windowDividerW) * 20)
    box-shadow: calc(var(--windowDividerW) * -23) 0 calc(var(--windowDividerW) * 44) rgba(23, 22, 22, 0.25)
    //min-height: 100dvh

  &#{$block}_atlas
    width: 100%
    max-width: calc(var(--windowDividerW) * 444)

  @media only screen and (max-width: 1023px)
    &#{$block}_modal
      width: 100vw

    &#{$block}_to-right
      border-radius: 20px 20px 0 0
