@import "../../../const"

.#{$ns}card
  $block: #{&}
  $blockWithMod: #{$block}_modal

  &#{$blockWithMod}

    #{$block}__text
      font-family: $font-base
      font-style: normal
      font-weight: 300
      font-size: calc(var(--windowDividerW) * 20)
      line-height: calc(var(--windowDividerW) * 32)
      color: var(--bm-card-modal-title)


      &#{$block}__text_second
        font-size: calc(var(--windowDividerW) * 14)
        line-height: calc(var(--windowDividerW) * 20)

    @media only screen and (max-width: 767px)
      #{$block}__text
        font-size: 18px
        line-height: 26px

        &#{$block}__text_second
          font-size: 14px
          line-height: 20px

    @media only screen and (min-width: 768px) and (max-width: 1023px)
      #{$block}__text
        font-size: 18px
        line-height: 28px

        &#{$block}__text_second
          font-size: 14px
          line-height: 20px

    @media only screen and (min-width: 1024px) and (max-width: 1439px)
      #{$block}__text
        font-size: 18px
        line-height: 28px

        &#{$block}__text_second
          font-size: 14px
          line-height: 20px

    @media only screen and (min-width: 1440px) and (max-width: 1919px)
      #{$block}__text
        font-size: 18px
        line-height: 28px

        &#{$block}__text_second
          font-size: 14px
          line-height: 20px
