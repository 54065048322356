@import "../../const"

.#{$ns}header
  $block: #{&}
  $blockWithMod: #{$block}_top

  &#{$blockWithMod}
    padding: calc(var(--windowDividerH) * 10) calc(var(--windowDividerW) * 180)
    height: calc(var(--windowDividerH) * 74)
    display: flex
    align-items: center
    background: var(--bm-header-atlas-background)
    border-bottom: var(--bm-header-atlas-border)
    box-shadow: var(--bm-header-atlas-shadow)
    backdrop-filter: var(--bm-header-atlas-backdrop)

    #{$block}__title
      font-family: $font-base-extend
      font-style: normal
      font-weight: 700
      font-size: calc(var(--windowDividerW) * 32)
      line-height: calc(var(--windowDividerW) * 36)
      text-transform: uppercase
      color: var(--bm-header-text)
      position: absolute
      top: 50%
      transform: translateY(-50%)
      left: calc(var(--windowDividerW) * 18)
      letter-spacing: calc(var(--windowDividerW) * 2)

  @media only screen and (max-width: 767px)
    &#{$blockWithMod}
      padding: 11px 20px
      height: auto
      border-radius: 0 0 12px 12px

      #{$block}__title
        display: none

  @media only screen and (min-width: 768px) and (max-width: 1023px)
    &#{$blockWithMod}
      padding: 11px 20px
      height: auto
      border-radius: 0 0 12px 12px

      #{$block}__title
        display: none

  @media only screen and (min-width: 1024px) and (max-width: 1439px)
    &#{$blockWithMod}
      height: 60px

      #{$block}__title
        font-size: 24px
        line-height: 28px
        letter-spacing: 1.6px

  @media only screen and (min-width: 1440px) and (max-width: 1919px)
    &#{$blockWithMod}
      height: 60px

      #{$block}__title
        font-size: 28px
        line-height: 32px
        letter-spacing: 1.6px
