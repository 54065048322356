.bm-tn
  &_modal
    &_enter
      opacity: 0
      visibility: hidden
      transform: translateY(calc(var(--windowDividerH) * -40))

    &_enter-active
      opacity: 1
      visibility: visible
      transform: translateY(0)
      transition: all 300ms

    &_exit
      opacity: 1
      visibility: visible
      transform: translateY(0)

    &_exit-active
      opacity: 0
      visibility: hidden
      transform: translateY(calc(var(--windowDividerH) * -40))
      transition: all 300ms

  &_fade
    &_enter
      opacity: 0
      visibility: hidden

    &_enter-active
      opacity: 1
      visibility: visible
      transition: all .4s

    &_exit
      visibility: visible
      opacity: 1

    &_exit-active
      opacity: 0
      visibility: hidden
      transition: all .4s

  &_drawer
    &_enter
      opacity: 0
      visibility: hidden
      transform: translateX(100%)

    &_enter-active
      opacity: 1
      visibility: visible
      transform: translateX(0)
      transition: all 300ms

    &_exit
      opacity: 1
      visibility: visible
      transform: translateX(0)

    &_exit-active
      opacity: 0
      visibility: hidden
      transform: translateX(100%)
      transition: all 300ms

    @media only screen and (max-width: 768px)
      &_enter
        opacity: 0
        visibility: hidden
        transform: translateY(100%)

      &_enter-active
        opacity: 1
        visibility: visible
        transform: translateY(0)
        transition: all 300ms

      &_exit
        opacity: 1
        visibility: visible
        transform: translateY(0)

      &_exit-active
        opacity: 0
        visibility: hidden
        transform: translateY(100%)
        transition: all 300ms
