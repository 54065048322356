@font-face
  font-family: 'RussianRail G Pro'
  font-weight: 400
  font-style: normal
  src: url("./RussianRailGPro-Reg.woff") format('woff'), url("./RussianRailGPro-Reg.woff2") format('woff2')

@font-face
  font-family: 'RussianRail G Pro'
  font-weight: 500
  font-style: normal
  src: url("./RussianRailGPro-Medium.woff") format('woff'), url("./RussianRailGPro-Medium.woff2") format('woff2')

@font-face
  font-family: 'RussianRail G Pro'
  font-weight: 600
  font-style: normal
  src: url("./RussianRailGPro-Bold.woff") format('woff'), url("./RussianRailGPro-Bold.woff2") format('woff2')

@font-face
  font-family: 'RussianRail G Pro Extend'
  font-weight: 400
  font-style: normal
  src: url("./RussianRailGPro-Extended.woff") format('woff'), url("./RussianRailGPro-Extended.woff2") format('woff2')

@font-face
  font-family: 'RussianRail G Pro Extend'
  font-weight: 700
  font-style: normal
  src: url("./RussianRailGPro-ExtendedBold.woff") format('woff'), url("./RussianRailGPro-ExtendedBold.woff2") format('woff2')

