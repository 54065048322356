@mixin placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content

@mixin touchSelection
  -webkit-tap-highlight-color: transparent !important
  -webkit-touch-callout: none !important
  -webkit-user-select: none !important
  -khtml-user-select: none !important
  -moz-user-select: none !important
  -ms-user-select: none !important
  user-select: none !important
