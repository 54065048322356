@import "../../const"
@import "../../mixin"

.#{$ns}header
  $block: #{&}
  $blockWithMod: #{$block}_atlas

  &#{$blockWithMod}

    #{$block}__navs
      text-align: center
      width: 100%

    #{$block}__subtitle
      color: var(--bm-header-atlas-subtitle-color)
      font-family: $font-base-extend
      font-style: normal
      font-weight: 400
      font-size: calc(var(--windowDividerW) * 16)

    #{$block}__current
      display: flex
      align-items: center
      justify-content: center
      margin-top: calc(var(--windowDividerH) * 3)
      cursor: pointer
      outline: none
      @include touchSelection

    #{$block}__text
      font-family: $font-base
      font-style: normal
      font-weight: 400
      font-size: calc(var(--windowDividerW) * 24)
      color: var(--bm-header-atlas-text-color)

    #{$block}__arrow
      width: calc(var(--windowDividerW) * 24)
      height: calc(var(--windowDividerW) * 24)
      margin-left: calc(var(--windowDividerW) * 14)

  @media only screen and (max-width: 767px)
    &#{$blockWithMod}
      #{$block}__subtitle
        display: none

      #{$block}__text
        font-style: normal
        font-weight: 400
        font-size: 14px
        line-height: 17px
        text-align: left

  @media only screen and (min-width: 768px) and (max-width: 1023px)
    &#{$blockWithMod}
      #{$block}__subtitle
        display: none

      #{$block}__text
        font-style: normal
        font-weight: 400
        font-size: 14px
        line-height: 17px
        text-align: left

  @media only screen and (min-width: 1024px) and (max-width: 1439px)
    &#{$blockWithMod}
      #{$block}__current
        margin-top: -3px

      #{$block}__text
        font-size: 22px

  @media only screen and (min-width: 1440px) and (max-width: 1919px)
    &#{$blockWithMod}
      #{$block}__current
        margin-top: 0

      #{$block}__text
        font-size: 22px
