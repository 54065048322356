@import "../../const"

.#{$ns}menu
  $block: #{&}
  $blockWithMod: #{$block}_atlas

  &#{$blockWithMod}
    display: flex
    flex-direction: column

