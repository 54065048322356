@import "../../../const"

.#{$ns}card
  $block: #{&}
  $blockWithMod: #{$block}_modal

  &#{$blockWithMod}

    #{$block}__close
      display: flex
      align-items: center
      justify-content: center
      font-family: $font-base-extend
      font-weight: 700
      font-size: calc(var(--windowDividerW) * 16)
      line-height: calc(var(--windowDividerW) * 20)
      text-transform: uppercase
      color: var(--bm-card-modal-close)

    #{$block}__icon
      width: calc(var(--windowDividerW) * 48)
      height: calc(var(--windowDividerW) * 48)
      margin-left: calc(var(--windowDividerW) * 16)

      #{$block}__icon-svg
       width: calc(var(--windowDividerW) * 48)
       height: calc(var(--windowDividerW) * 48)

