@import "../../../const"

.#{$ns}card
  $block: #{&}
  $blockWithMod: #{$block}_modal

  &#{$blockWithMod}

    #{$block}__grid
      display: flex
      justify-content: space-between

