@import "../../../const"

.#{$ns}card
  $block: #{&}
  $blockWithMod: #{$block}_modal
  $blockWithModAtlas: #{$block}_atlas

  &#{$blockWithMod}

    #{$block}__content
      padding: calc(var(--windowDividerH) * 68) calc(var(--windowDividerW) * 64)

  &#{$blockWithMod}#{$blockWithModAtlas}
    #{$block}__content
      padding: calc(var(--windowDividerW) * 16)


    @media only screen and (max-width: 767px)
      #{$block}__content
        padding: 36px 20px

      &#{$blockWithMod}#{$blockWithModAtlas}
        #{$block}__content
          padding: 16px

    @media only screen and (min-width: 768px) and (max-width: 1023px)
      #{$block}__content
        padding: 52px 72px

      &#{$blockWithMod}#{$blockWithModAtlas}
        #{$block}__content
          padding: 16px


    @media only screen and (min-width: 1024px) and (max-width: 1439px)
      #{$block}__content
        padding: 52px 120px

      &#{$blockWithMod}#{$blockWithModAtlas}
        #{$block}__content
          padding: 16px


    @media only screen and (min-width: 1440px) and (max-width: 1919px)
      #{$block}__content
        padding: 52px 48px

      &#{$blockWithMod}#{$blockWithModAtlas}
        #{$block}__content
          padding: 16px
