@import "../../../const"

.#{$ns}card
  $block: #{&}
  $blockWithModMobile: #{$block}_mobile
  $blockWithModModal: #{$block}_modal
  $blockWithModAtlas: #{$block}_atlas

  &#{$blockWithModModal}
    #{$block}__header
      padding: calc(var(--windowDividerH) * 16) calc(var(--windowDividerW) * 24)
      display: flex
      justify-content: flex-end
      border-bottom: var(--bm-card-modal-line)

  &#{$blockWithModAtlas}
    #{$block}__header
      border-bottom: 0
      padding: calc(var(--windowDividerW) * 15) calc(var(--windowDividerW) * 15) 0

  &#{$blockWithModMobile}
    #{$block}__header
      justify-content: center
      padding: 0 0 calc(var(--windowDividerH) * 5)
      border-bottom: 0
