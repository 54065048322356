@import "../../../const"

.#{$ns}card
  $block: #{&}
  $blockWithMod: #{$block}_modal

  &#{$blockWithMod}

    #{$block}__line
      border-bottom: var(--bm-card-modal-line)
      margin: calc(var(--windowDividerH) * 48) 0

    @media only screen and (max-width: 767px)
      #{$block}__line
        margin: 24px 0

    @media only screen and (min-width: 768px) and (max-width: 1023px)
      #{$block}__line
        margin: 36px 0

    @media only screen and (min-width: 1024px) and (max-width: 1439px)
      #{$block}__line
        margin: 36px 0

    @media only screen and (min-width: 1440px) and (max-width: 1919px)
      #{$block}__line
        margin: 40px 0
