@import "../const"

.#{$ns}map-control-group
  $block: #{&}

  &#{$block}
    position: relative
    display: flex
    overflow: hidden

  &#{$block}_horizontal
    flex-direction: row

  &#{$block}_vertical
    flex-direction: column

  &#{$block}_rounded
    border-radius: calc(var(--windowDividerW) * 50)

  &#{$block}_gradient
    background: var(--bm-card-background)
    backdrop-filter: var(--bm-card-blur)
    box-shadow: var(--bm-card-shadow)
