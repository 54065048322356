@import "../const"

.#{$ns}map-space
  $block: #{&}
  position: absolute
  display: flex
  z-index: 3
  padding: calc(var(--windowDividerW) * 12)

  &#{$block}_pressed
    padding: 0

  &#{$block}_full
    width: 100%

  &#{$block}_horizontal
    flex-direction: row

  &#{$block}_vertical
    flex-direction: column

  &#{$block}_right-top
    top: 0
    right: 0
    z-index: 6

  &#{$block}_right-center
    top: 50%
    transform: translateY(-50%)
    right: 0

  &#{$block}_right-bottom
    bottom: 0
    right: 0
    z-index: 4

  &#{$block}_left-top
    top: 0
    left: 0
    z-index: 5

  &#{$block}_left-center
    top: 50%
    transform: translateY(-50%)
    left: 0

  &#{$block}_left-bottom
    bottom: 0
    left: 0
    z-index: 6
    align-items: flex-start

  &#{$block}_bottom-center
    bottom: 0
    left: 50%
    transform: translateX(-50%)
    z-index: 4
    align-items: center
