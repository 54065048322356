@import "../../../const"

.#{$ns}menu
  $block: #{&}
  $blockWithMod: #{$block}_atlas

  &#{$blockWithMod}

    #{$block}__item + #{$block}__item
      margin-top: calc(var(--windowDividerH) * 5)

    #{$block}__item-inner
      padding: calc(var(--windowDividerW) * 9) calc(var(--windowDividerW) * 20) calc(var(--windowDividerW) * 8)
      font-family: $font-base
      background: transparent
      font-style: normal
      font-weight: 400
      font-size: calc(var(--windowDividerW) * 24)
      line-height: calc(var(--windowDividerW) * 28)
      text-decoration: none
      transition: all .2s
      color: var(--bm-menu-atlas-link-color)
      display: inline-block
      width: 100%
      border-radius: calc(var(--windowDividerW) * 24)

    #{$block}__item
      &_active
        #{$block}__item-inner
          color: var(--bm-menu-atlas-link-active-color)
          background: var(--bm-menu-atlas-link-active-bg)

    @media only screen and (max-width: 1023px)

      #{$block}__item-inner
        font-size: 16px
        line-height: 20px

    @media(hover: hover) and (pointer: fine)
      #{$block}__item
        &:hover
          #{$block}__item-inner
            color: var(--bm-menu-atlas-link-active-color)
