.bm-modal
  position: relative
  z-index: 99

  &__overlay
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 100
    height: 100%
    overflow: auto
    background: rgba(0, 0, 0, .7)
    text-align: center
    opacity: 0
    transition: opacity .2s

    &_opacity
      background: rgba(0, 0, 0, .2)

    &_show
      opacity: 1

    &:before
      display: inline-block
      width: 0
      height: 100%
      vertical-align: middle
      content: ""

  &__inner
    display: flex
    align-items: center
    justify-content: center

  &__content
    padding: calc(var(--windowDividerH) * 24) 0
    position: relative
    width: auto
    margin: 0 auto
    display: inline-block
    text-align: left
    vertical-align: middle
    outline: none
