@import "../../const"

.#{$ns}card
  $block: #{&}

  &#{$block}_default
    background: var(--bm-card-background)
    backdrop-filter: var(--bm-card-blur)
    box-shadow: var(--bm-card-shadow)
    border-radius: calc(var(--windowDividerW) * 24)
    overflow: hidden

  &#{$block}_angular
    border-radius: 0
