@import "../../const"

.#{$ns}layout
  $block: #{&}
  $blockWithMod: #{$block}_atlas

  &#{$blockWithMod}
    position: relative
    width: 100vw
    height: 100vh
    height: 100dvh
    overflow: hidden

    #{$block}__map
      position: absolute
      top: 0
      left: 0
      z-index: 1

    #{$block}__header
      position: fixed
      top: 0
      left: 0
      width: 100%
      z-index: 10

    #{$block}__left-bottom_zoom
      bottom: calc(var(--windowDividerH) * 40)
      left: calc(var(--windowDividerW) * 40)

    //@media only screen and (max-width: 767px)
    //@media only screen and (min-width: 768px) and (max-width: 1023px)
    @media only screen and (max-width: 1023px)

      #{$block}__left-bottom_zoom
        display: none
