@import "../const"

.body-theme_light,
.#{$ns}theme_light
  --bm-header-text: #141414
  --bm-header-shadow: rgba(35,47,56,1)
  --bm-header-atlas-background: rgba(255, 255, 255, 0.8)
  --bm-header-atlas-border: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, 0.3)
  --bm-header-atlas-shadow: 0 calc(var(--windowDividerW) * 12) calc(var(--windowDividerW) * 22) rgba(0, 0, 0, 0.14)
  --bm-header-atlas-backdrop: blur(10px)
  --bm-header-atlas-subtitle-color: #254C61
  --bm-header-atlas-text-color: #141414

  --bm-menu-atlas-link-color: #141414
  --bm-menu-atlas-link-active-color: #141414
  --bm-menu-atlas-link-active-bg: rgba(101, 135, 154, 0.4)

  --bm-atlas-cluster-color: #FFFFFF
  --bm-atlas-cluster-outline: #141414

  --bm-atlas-cluster-podgotovka-kadrov-color: #65879A
  --bm-atlas-cluster-podgotovka-kadrov-bg: #FFF
  --bm-atlas-cluster-podgotovka-kadrov-border: calc(var(--windowDividerW) * 2) solid #65879A

  --bm-card-background:  linear-gradient(193.6deg, rgba(83, 203, 255, 0.2) -124.06%, rgba(83, 203, 255, 0) 94.74%), rgba(255, 255, 255, 0.6)
  --bm-card-background-no-opacity:  linear-gradient(193.6deg, rgba(83, 203, 255, 0.3) -124.06%, rgba(83, 203, 255, 0) 94.74%), rgba(255, 255, 255, 0.7)
  --bm-card-blur: blur(10px)
  --bm-card-shadow: 0 calc(var(--windowDividerW) * 12) calc(var(--windowDividerW) * 22) rgba(0, 0, 0, 0.14)
  --bm-card-section-bg: #FFFFFF
  --bm-card-title: #141414
  --bm-card-text: rgba(45, 77, 95, 0.7)
  --bm-card-prop-color: #65879A
  --bm-card-value-color: #141414
  --bm-card-text-smoky: #65879A
  --bm-card-number: #2D4D5F
  --bm-card-unit: #65879A
  --bm-card-line: calc(var(--windowDividerW) * 1) solid rgba(101, 135, 154, 0.3)
  --bm-card-progress: rgba(101, 135, 154, 0.2)
  --bm-card-percent: #E21A1A
  --bm-card-percent-shadow: 0 0 calc(var(--windowDividerW) * 8) rgba(255, 0, 0, 0.5)

  --bm-timeline-bg: rgba(255, 255, 255, 0.3)
  --bm-timeline-text: #2D4D5F
  --bm-timeline-text-hover: rgba(45, 77, 95, 0.8)
  --bm-timeline-active-text: #FFFFFF
  --bm-timeline-active-bg: #65879A
  --bm-timeline-active-shadow: 0 calc(var(--windowDividerW) * 4) calc(var(--windowDividerW) * 52) rgba(11, 16, 26, 0.44)
  --bm-timeline-helper-text: #0d161a

  --bm-marker-base-bg: #E21A1A
  --bm-marker-base-pulsu: rgba(226, 26, 26, 0.7)
  --bm-marker-base-card:  linear-gradient(193.6deg, rgba(83, 203, 255, 0.2) -124.06%, rgba(83, 203, 255, 0) 94.74%), rgba(255, 255, 255, 0.6)
  --bm-marker-base-border: #FFFFFF
  --bm-marker-base-count: #FFFFFF
  --bm-marker-base-title: #6387A8
  --bm-marker-base-subtitle: #141414
  --bm-marker-base-text: #141414
  --bm-marker-base-radius: rgba(171, 214, 249, 1)

  --bm-marker-circle-chart-border: #FFFFFF

  --bm-marker-circle-text: #FFFFFF
  --bm-marker-circle-text-shadow: 0 0 calc(var(--windowDividerW) * 2) #0E1D2B, 0 0 calc(var(--windowDividerW) * 3) #0E1D2B

  --bm-button-base-bg: rgba(255, 255, 255, 0.5)
  --bm-button-base-active-bg: #FFFFFF
  --bm-button-base-text: #141414
  --bm-button-base-name: rgba(14, 29, 43, 1)
  --bm-button-base-name-shadow: 0 0 calc(var(--windowDividerW) * 2) #FFF, 0 0 3px #FFF
  --bm-button-base-second: #65879A
  --bm-button-base-active-second: #65879A
  --bm-button-base-shadow: 0 calc(var(--windowDividerW) * 12) calc(var(--windowDividerW) * 22) rgba(0, 0, 0, 0.14)
  --bm-button-base-blur: blur(10px)
  --bm-button-base-flat-border: calc(var(--windowDividerW) * 1) solid rgba(101, 135, 154, 0.3)

  --bm-stage-title: #65879A
  --bm-stage-popover-bg: rgba(255, 255, 255, 0.70)
  --bm-stage-popover-blur: blur(10px)
  --bm-stage-popover-button-bg: rgba(101, 135, 154, 0.1)
  --bm-stage-popover-button-color: rgba(14, 29, 43, 1)
  --bm-stage-popover-button-border: rgba(101, 135, 154, 0.30)

  --bm-card-modal-close: #65879A
  --bm-card-modal-line: calc(var(--windowDividerW) * 1) solid rgba(101, 135, 154, 0.3)
  --bm-card-modal-title: #141414
  --bm-card-modal-text: #141414

  --bm-table-row-hover: rgba(101, 135, 154, 0.1)
  --bm-table-head-text: rgba(111, 118, 126, 1)
  --bm-table-cell-text: #141414
  --bm-table-cell-text-second: rgba(144, 149, 158, 1)

  --bm-map-popup-image-title: #0E1D2B
  --bm-map-popup-image-title-bg: rgba(255, 255, 255, 0.7)

  --bm-map-popup-atlas-bg: rgba(255, 255, 255, 0.8)
  --bm-map-popup-atlas-shadow: 0 calc(var(--windowDividerW) * 10) calc(var(--windowDividerW) * 18) rgba(0, 0, 0, 0.14)
  --bm-map-popup-atlas-arrow: rgba(255, 255, 255, .8)
  --bm-map-popup-atlas-title: #141414
  --bm-map-popup-atlas-text: #141414
  --bm-map-popup-atlas-subtitle: #65879A
  --bm-map-popup-atlas-subtitle-second: #141414
  --bm-map-popup-atlas-divider: calc(var(--windowDividerW) * 1) solid rgba(101, 135, 154, 0.3)

  --bm-card-legend-title: #FFFFF
  --bm-card-legend-text: #FFFFF
  --bm-card-legend-divider: calc(var(--windowDividerW) * 1) solid rgba(101, 135, 154, 0.3)

  --bm-scollbar-track-color: rgba(101, 135, 154, 0.3)
  --bm-scollbar-thumb-color: #65879A

  --bm-card-object-bg: rgba(255, 255, 255, 0.70)
  --bm-card-object-shadow: calc(var(--windowDividerW) * -23) 0 calc(var(--windowDividerW) * 44) 0 rgba(23, 22, 22, 0.25)
  --bm-card-object-title: #0E1D2B
  --bm-card-object-text: #0E1D2B
  --bm-card-object-error: #65879A
  --bm-card-object-line: calc(var(--windowDividerW) * 2) solid rgba(83, 203, 255, .3)
  --bm-card-object-subtitle: #65879A
  --bm-card-object-expander: rgba(226, 26, 26, 1)
  --bm-card-object-gallery-current-border: calc(var(--windowDividerW) * 1) solid #626C75
  --bm-card-object-gallery-item-border: calc(var(--windowDividerW) * 1) solid #626C75
  --bm-card-object-events-title: #ABD6F9
  --bm-card-object-events-desc: #2D4D5F
  --bm-card-object-events-year-sep: #65879A
  --bm-card-object-events-icon-border: calc(var(--windowDividerW) * 1) solid #65879A
  --bm-card-object-row-text-border: calc(var(--windowDividerW) * 1) solid #65879A
  --bm-card-object-row-text-name: #0E1D2B
  --bm-card-object-row-text-value: #0E1D2B

  --bm-select-item-color: #2D4D5F
  --bm-select-item-hover: rgba(101, 135, 154, 0.40)
  --bm-select-item-current: rgba(14, 29, 43, 1)

  --bm-input-base-bg: #FFFFFF
  --bm-input-base-border: none
  --bm-input-base-error-border: #E21A1A
  --bm-input-base-focus-border: #53CBFF
  --bm-input-base-placeholder: #65879A
  --bm-input-base-text: #65879A
  --bm-input-base-separator: #65879A
