@import "../../../const"

.#{$ns}card
  $block: #{&}
  $blockWithMod: #{$block}_modal

  &#{$blockWithMod}
    #{$block}__space
      width: 100%
      height: calc(var(--windowDividerH) * 5)
