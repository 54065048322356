@import "../const"

.body-theme_dark,
.#{$ns}theme_dark
  --bm-header-text: #FFFFFF
  --bm-header-shadow: rgba(35,47,56,1)
  --bm-header-atlas-background: rgba(14, 29, 43, 0.7)
  --bm-header-atlas-border: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, 0.3)
  --bm-header-atlas-shadow: 0 calc(var(--windowDividerW) * 12) calc(var(--windowDividerW) * 22) rgba(0, 0, 0, 0.14)
  --bm-header-atlas-backdrop: blur(10px)
  --bm-header-atlas-subtitle-color: #65879A
  --bm-header-atlas-text-color: #FFFFFF

  --bm-menu-atlas-link-color: #FFFFFF
  --bm-menu-atlas-link-active-color: #53CBFF
  --bm-menu-atlas-link-active-bg: rgba(101, 135, 154, 0.4)

  --bm-atlas-cluster-color: #2D4D5F
  --bm-atlas-cluster-outline: #FFFFFF

  --bm-atlas-cluster-podgotovka-kadrov-color: #FFF
  --bm-atlas-cluster-podgotovka-kadrov-bg: #65879A
  --bm-atlas-cluster-podgotovka-kadrov-border: calc(var(--windowDividerW) * 2) solid #FFF

  --bm-card-background: linear-gradient(148.39deg, rgba(148, 219, 250, 0.3) -29.1%, rgba(107, 193, 255, 0) 138.73%), rgba(29, 30, 33, 0.85)
  --bm-card-background-no-opacity: linear-gradient(148.39deg, rgba(148, 219, 250, 0.3) -29.1%, rgba(107, 193, 255, 0) 138.73%), rgba(29, 30, 33, 0.85)
  --bm-card-blur: blur(10px)
  --bm-card-shadow: 0 calc(var(--windowDividerW) * 12) calc(var(--windowDividerW) * 22) rgba(0, 0, 0, 0.14)
  --bm-card-section-bg: rgba(14, 29, 43, 0.5)
  --bm-card-title: #FFFFFF
  --bm-card-prop-color: #65879A
  --bm-card-value-color: #FFFFFF
  --bm-card-text: rgba(255, 255, 255, 0.7)
  --bm-card-text-smoky: rgba(171, 214, 249, 0.7)
  --bm-card-number: #FFFFFF
  --bm-card-unit: #65879A
  --bm-card-line: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, 0.3)
  --bm-card-progress: rgba(83, 203, 255, 0.2)
  --bm-card-percent: #E21A1A
  --bm-card-percent-shadow: 0 0 calc(var(--windowDividerW) * 16) #FF0000

  --bm-timeline-bg: rgba(101, 135, 154, 0.3)
  --bm-timeline-text: rgba(255, 255, 255, 0.49)
  --bm-timeline-text-hover: rgba(255, 255, 255, 0.7)
  --bm-timeline-active-text: #FFFFFF
  --bm-timeline-active-bg: #0E1D2B
  --bm-timeline-active-shadow: 0 calc(var(--windowDividerW) * 4) calc(var(--windowDividerW) * 52) rgba(11, 16, 26, 0.44)
  --bm-timeline-helper-text: #ABD6F9

  --bm-marker-base-bg: #E21A1A
  --bm-marker-base-pulsu: rgba(255, 255, 255, 0.7)
  --bm-marker-base-border: #FFFFFF
  --bm-marker-base-count: #FFFFFF
  --bm-marker-base-title: #7daad3
  --bm-marker-base-subtitle: #FFFFFF
  --bm-marker-base-text: #141414
  --bm-marker-base-radius: rgba(171, 214, 249, 1)

  --bm-marker-circle-chart-border: #0E1D2B

  --bm-marker-circle-text: #FFFFFF
  --bm-marker-circle-text-shadow: 0 0 calc(var(--windowDividerW) * 2) #0E1D2B, 0 0 calc(var(--windowDividerW) * 3) #0E1D2B

  --bm-button-base-bg: rgba(101, 135, 154, 0.2)
  --bm-button-base-active-bg: #65879A
  --bm-button-base-text: #FFFFFF
  --bm-button-base-name: #FFFFFF
  --bm-button-base-name-shadow: 0 0 calc(var(--windowDividerW) * 5) #0E1D2B, 0 0 calc(var(--windowDividerW) * 7) #0E1D2B
  --bm-button-base-second: #65879A
  --bm-button-base-active-second: #FFFFFF
  --bm-button-base-shadow: 0 calc(var(--windowDividerW) * 12) calc(var(--windowDividerW) * 22) rgba(0, 0, 0, 0.14)
  --bm-button-base-blur: blur(10px)
  --bm-button-base-flat-border: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, 0.3)

  --bm-stage-title: rgba(171, 214, 249, 0.5)
  --bm-stage-popover-bg: rgba(14, 29, 43, 0.7)
  --bm-stage-popover-blur: blur(10px)
  --bm-stage-popover-button-bg: rgba(16, 23, 33, 0.55)
  --bm-stage-popover-button-color: #FFFFFF
  --bm-stage-popover-button-border: transparent

  --bm-card-modal-close: #ABD6F9
  --bm-card-modal-line: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, 0.3)
  --bm-card-modal-title: #FFFFFF
  --bm-card-modal-text: #FFFFFF

  --bm-table-row-hover: rgba(101,135,154,.4)
  --bm-table-head-text: #65879a
  --bm-table-cell-text: #FFFFFF
  --bm-table-cell-text-second: #65879a

  --bm-map-popup-image-title: rgba(255, 255, 255, 0.7)
  --bm-map-popup-image-title-bg: rgba(28, 42, 49, 0.66)

  --bm-map-popup-atlas-bg: rgba(14, 29, 43, 0.7)
  --bm-map-popup-atlas-shadow: 0 calc(var(--windowDividerW) * 10) calc(var(--windowDividerW) * 18) rgba(0, 0, 0, 0.14)
  --bm-map-popup-atlas-arrow: rgba(14, 29, 43, 0.7)
  --bm-map-popup-atlas-title: #FFFFFF
  --bm-map-popup-atlas-text: #FFFFFF
  --bm-map-popup-atlas-subtitle: #65879A
  --bm-map-popup-atlas-subtitle-second: #ABD6F9
  --bm-map-popup-atlas-divider: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, 0.3)

  --bm-card-legend-title: #FFFFFF
  --bm-card-legend-text: #FFFFFF
  --bm-card-legend-divider: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, 0.3)

  --bm-scollbar-track-color: #65879A
  --bm-scollbar-thumb-color: #ABD6F9

  --bm-card-object-bg: rgba(93, 130, 161, 0.50)
  --bm-card-object-shadow: calc(var(--windowDividerW) * -23) 0 calc(var(--windowDividerW) * 44) 0 rgba(23, 22, 22, 0.25)
  --bm-card-object-title: #FFFFFF
  --bm-card-object-text: #FFFFFF
  --bm-card-object-error: #ABD6F9
  --bm-card-object-line: calc(var(--windowDividerW) * 2) solid rgba(83, 203, 255, 0.30)
  --bm-card-object-subtitle: #ABD6F9
  --bm-card-object-expander: rgba(255, 255, 255, 1)
  --bm-card-object-gallery-current-border: calc(var(--windowDividerW) * 1) solid #4E6876
  --bm-card-object-gallery-item-border: calc(var(--windowDividerW) * 1) solid #53CBFF
  --bm-card-object-events-title: #FFFFFF
  --bm-card-object-events-desc: #ABD6F9
  --bm-card-object-events-year-sep: rgba(83, 203, 255, .3)
  --bm-card-object-events-icon-border: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, .3)
  --bm-card-object-row-text-border: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, .3)
  --bm-card-object-row-text-name: #FFF
  --bm-card-object-row-text-value: #FFF

  --bm-select-item-color: #FFFFFF
  --bm-select-item-hover: rgba(101, 135, 154, 0.40)
  --bm-select-item-current: rgba(255, 255, 255, 1)

  --bm-input-base-bg: rgba(14, 29, 43, 0.50)
  --bm-input-base-border: calc(var(--windowDividerW) * 1) solid rgba(83, 203, 255, 0.30)
  --bm-input-base-error-border: #E21A1A
  --bm-input-base-focus-border: #53CBFF
  --bm-input-base-placeholder: #65879A
  --bm-input-base-text: #65879A
  --bm-input-base-separator: rgba(83, 203, 255, 0.30)
