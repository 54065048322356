@import "../../const"

.#{$ns}modal
  $block: #{&}

  &#{$block}_base
    background: none

    &#{$block}_top
      #{$block}__overlay
        &:before
          display: none

      #{$block}__content
        padding-top: calc(var(--windowDividerH) * 30)

    #{$block}__overlay
      background: none

    &#{$block}_flat
      #{$block}__content
        padding: 0
        width: 100vw
        height: 100vh
